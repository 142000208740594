
  
  import {gmapApi} from 'vue2-google-maps'
import * as geolib from 'geolib';
  
  
  const stringOptions = ([
    { text: 'BASALA', value: 'BASALA' }, { text: 'BENUA', value: 'BENUA' }, { text: 'BUKE', value: 'BUKE' }, { text: 'KONDA', value: 'KONDA' }, { text: 'LAEYA', value: 'LAEYA' }, { text: 'LALEMBUU', value: 'LALEMBUU' }, { text: 'LANDONO', value: 'LANDONO' }, { text: 'PALANGGA', value: 'PALANGGA' },   { text: 'MOWILA', value: 'MOWILA' },
    { text: 'KOLONO TIMUR', value: 'KOLONO TIMUR' },
    { text: 'SABULAKOA', value: 'SABULAKOA' },
    { text: 'ANDOOLO BARAT', value: 'ANDOOLO BARAT' },
    { text: 'ANDOOLO', value: 'ANDOOLO' },
    { text: 'ANGATA', value: 'ANGATA' },
    { text: 'BAITO', value: 'BAITO' },
    { text: 'PALANGGA SELATAN', value: 'PALANGGA SELATAN' },
    { text: 'RANOMEETO', value: 'RANOMEETO' },
    { text: 'RANOMEETO BARAT', value: 'RANOMEETO BARAT' },
    { text: 'WOLASI', value: 'WOLASI' },
    { text: 'KOLONO', value: 'KOLONO' },
    { text: 'LAINEA', value: 'LAINEA' },
    { text: 'LAONTI', value: 'LAONTI' },
    { text: 'MORAMO', value: 'MORAMO' },
    { text: 'MORAMO UTARA', value: 'MORAMO UTARA' },
    { text: 'TINANGGEA', value: 'TINANGGEA' },
    
  ])
  
  
  
  export default {
    data() {
      return {
        // Tombol Modal
        tambah: false,
        edit: false,
        lihat: false,
        modal_lokasi: false,
        mdl_hapus: false,
  
        // Tombol Save Modal
        simpan1: false,
        simpan2: false,
        //
      options: stringOptions,
  
        markers: [{
          position: {
            lat: -4.250462,
            lng: 122.1291645
          }
        }],
  
        form : {
          id: '',
          zona: '',
          luas_zona: '',
          kecamatan: '',
          nilai: '',
          file: '',
        },
        lokasi: [],
        centroid: null,
        //
        file_old: "",
        file_path: this.$store.state.url.URL_APP + "uploads/",
  
        no: 0,
        id: '',
        url: {
          GET_URL: this.$store.state.url.URL_APP + "api/v1/web_bataskonsel/"
        },
        list_data: [],
  
        page_first: 1,
        page_last: 0,
        cari_value: "",
  
      }
    },
    methods: {
    
    setModel (val) {
        this.form.kecamatan = val
  
      },
  
      mapping: function(data) {
  
        var tampung = [];
  
        for(var i = 0; i < data.length; i++) {
            var obj = data[i];
  
            tampung.push({"lat":parseFloat(obj.lat),"lng":parseFloat(obj.lng)});
        }
  
        return tampung;
  
      },
  
      tanda: function(data) {
        
  
        var lat_kecil, lat_besar, lng_kecil, lng_besar, hasil = {};
  
        for(var i = 0; i < data.length; i++) {
  
            if (!lat_besar || parseFloat(data[i][lat]) > parseFloat(lat_besar[lat])){
              lat_besar = data[i];
            }
  
            if (!lng_besar || parseFloat(data[i][lng]) > parseFloat(lng_besar[lng])){
              lng_besar = data[i];
            }
  
            if (!lat_kecil || parseFloat(data[i][lat]) < parseFloat(lat_kecil[lat])){
              lat_kecil = data[i];
            }
  
            if (!lng_kecil || parseFloat(data[i][lng]) < parseFloat(lng_kecil[lng])){
              lng_kecil = data[i];
            }
  
        }
  
        var hasil_lat_besar = lat_besar.lat;
        var hasil_lat_kecil = lat_kecil.lat;
        var hasil_lng_besar = lng_besar.lng;
        var hasil_lng_kecil = lng_kecil.lng;
  
  
        var lat = hasil_lat_kecil + ((hasil_lat_besar - hasil_lat_kecil) / 2);
        var lng = hasil_lng_kecil + ((hasil_lng_besar - hasil_lng_kecil) / 2);
  
        // hasil.push({"lat":parseFloat(lat),"lng":parseFloat(lng)});
  
        hasil = {"lat":parseFloat(lat),"lng":parseFloat(lng)}
        return hasil;
  
      },
  
      getView: function() {
        this.cek_load_data = true;
        fetch(this.url.GET_URL + "view", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
            })
          })
          .then(res => res.json())
          .then(res_data => {
  
            
            this.list_data = res_data[0].data1;
            this.page_last = res_data.lengthy;
            this.cek_load_data = false;
            
            // console.log(this.list_data)
            // 
  
  
          });
      },

      calculatePolygonCentroid() {
      const coordinates = this.lokasi.map((point) => ({
        latitude: point.lat,
        longitude: point.lng,
      }));

      const centroid = geolib.getCenter(coordinates);

      this.centroid = {
        lat: centroid.latitude,
        lng: centroid.longitude,
      };
    },
  
      addData: function() {
        
        this.calculatePolygonCentroid();

        console.log(this.centroid);


        var formData = new FormData();
  
        formData.append("zona", this.form.zona);
        formData.append("luas_zona", this.form.luas_zona);

        formData.append("kecamatan", this.form.kecamatan);
        formData.append("nilai", this.form.nilai);
        formData.append("file", this.form.file);
  
  
        formData.append("lokasi", JSON.stringify(this.lokasi));
  
        formData.append("marker", JSON.stringify(this.centroid));
        // formData.append("marker", JSON.stringify(this.tanda(this.lokasi)));
        
  
        fetch(this.url.GET_URL + "addData", {
          method: "POST",
          headers: {
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
        }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
          this.tambah = false;
        })
      },
  
      editData: function() {
  
        var formData = new FormData();
  
        formData.append("id", this.form.id);
        
        
        formData.append("zona", this.form.zona);
        formData.append("luas_zona", this.form.luas_zona);
        formData.append("kecamatan", this.form.kecamatan);
        formData.append("nilai", this.form.nilai);

        formData.append("file", this.form.file);
        formData.append("file_old", this.file_old);
  
        formData.append("lokasi", JSON.stringify(this.lokasi));
        formData.append("marker", JSON.stringify(this.tanda(this.lokasi)));
  
  
        fetch(this.url.GET_URL + "editData", {
          method: "POST",
          headers: {
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
        }).then(res_data => {
          // this.alertku("success", "Sukses merubah data");
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
          this.edit = false;
        });
      },
  
      selectData: function(data, string) {
  
        if(string == "edit"){
          this.edit = true;
  
          this.form = {
            id: data.id,

            zona: data.zona,
            luas_zona: data.luas_zona,
            nilai: data.nilai,
            kecamatan: data.kecamatan,
           
            file: data.file,
          };
          this.lokasi = data.lokasi;
          this.file_old = data.file;
        }
        else if(string == "lokasi"){
          this.modal_lokasi = true;
  
          this.form = {
            
            kecamatan: data.kecamatan,
          }
          this.lokasi = data.lokasi;
          
          
        }
        else if(string == "hapus"){
          this.mdl_hapus = true;
  
          this.form = {
            id: data.id,
            
            zona: data.zona,
            luas_zona: data.luas_zona,
            nilai: data.nilai,
            kecamatan: data.kecamatan,

            file: data.file,
          };
          this.lokasi = data.lokasi;
          this.file_old = data.file;
  
          
        }
        else if (string == "lihat") {
          this.lihat = true;
  
          this.form.file = data.file;
          this.form.nilai = data.nilai;
          
        }
  
  
        // CKEDITOR.instances.editor1.setData(data.isi);
      },
  
  
      removeData: function(idnya, file) {
        fetch(this.url.GET_URL + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            id: idnya,
            file: file
          })
        }).then(res_data => {
          this.getView();
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.mdl_hapus = false;
        });
      },
  
      simulateProgress(number) {
        // we set loading state
        this[`simpan${number}`] = true
        // simulate a delay
        setTimeout(() => {
          // we're done, we reset loading state
          this[`simpan${number}`] = false
        }, 3000)
      },
  
      cari_data: function() {
  
        this.page_first = 1;
        this.getView();
      },
  
      Notify : function(message, positive, icon){
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position : 'top',
          timeout: 500,
        })
      },
  
      
  
      onFileSelected: function(event) {
        this.form.file = event.target.files[0];
  
      },
  
      btn_prev: function() {
        this.cek_load_data = true;
        if (this.page_first > 1) {
          this.page_first--
        } else {
          this.page_first = 1;
          this.no -= 10;
        }
        this.getView();
      },
  
      btn_next: function() {
  
        if (this.page_first >= this.page_last) {
          this.page_first == this.page_last
        } else {
          this.page_first++;
          this.no += 10;
        }
        this.getView();
      },
  
      addLokasi: function () {
       this.lokasi.push({ lat: 0 , lng: 0});
      },
      hapusLokasi: function () {
       this.lokasi.splice(-1,1);
      }
  
    },
  
    computed: {
      google: gmapApi
    },
    mounted() {
  
      this.getView();
  
    }
  }
  